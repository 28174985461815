import React from 'react';
import Section from '../../../components/section';
import { PageHero } from '../../../components/page-hero';
import Layout from '../../../components/layout/layout';
import '../styles.scss';

const UseCasesItem = () => {
  return (
    <Layout>
      <PageHero
        title="Financial services compliance monitoring"
        subtitle=""
      />
      <Section>
        <div className="use-cases-item">
          <div className="go-back">
            <a className="learn_btn_two" href="/use-cases">
              <svg width="40px" height="20px" aria-hidden="true" focusable="false" data-prefix="fas"
                data-icon="long-arrow-alt-left" className="svg-inline--fa fa-long-arrow-alt-left fa-w-14 icon"
                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z">
                </path>
              </svg>Back to use cases</a>
          </div>

          <div className="content-block">
            <p>In highly regulated industries, where companies rely on external agents to sell or promote their products, 
              compliance can be a big challenge. This is especially true for businesses in the wealth management world, 
              such as insurance and lending services, because any information that is visible is considered advertising. 
               According to regulation, this information, or content, is subject to strict compliance oversight.</p>
            <p>In the USA, for instance, financial advisors are subject to the FINRAA 2210(b) rule, which outlines the following:</p>
            <p><i>
            Retail communication consists of any written (including electronic) communication that is distributed or 
            made available to more than 25 retail investors within any 30 calendar-day period. A retail investor is any 
            person other than an institutional investor, regardless of whether the person has an account with the firm.  
            </i></p>
            <p>A typical website for an advisor can include things like a biography, links to social media channels, 
              and blog posts. It is easy to see that, under the information described above, all of these would have to 
              be compliant with the law. This can be a difficult issue to navigate.</p>
            <h4 className="bold-title">Compliance Monitoring & Website Archiving for the financial services sector</h4>

            <p>Insurance companies that use independent agents to sell and/or promote their products are subject to the same regulations, 
              and even though these companies train their agents on how to describe products accurately in public communications, 
              it is very difficult to monitor.</p>

            <p>Online lenders, P2P players, and retail banks and credit card issuers have a similar 
              responsibility because they typically use a large, disconnected network of marketing affiliates. 
              These affiliates are given a financial incentive to produce leads.</p>

            <p>The problem arises when we take into account that even though compliance departments are very careful to 
              ensure that their offerings or products are represented fairly and that they include a legal disclaimer if necessary, 
              they have little control over how their materials may be edited or changed by affiliates after the compliance department’s 
              initial approval.</p>

            <p>Hexowatch can help you monitor your own website or affiliates for the inclusion or exclusion of key text fragments, 
              as well as the presence or absence of key terms. You can then receive an alert when a page is modified or 
              when key terms are modified or removed.</p>
            <p>Hexowatch can also help you archive changes made to a website so that you can create an audit trail of your key pages.</p>

            <h4>Go beyond compliance monitoring</h4>

            <p>Hexowatch is your AI sidekick to monitor any website for visual, content, source code, technology, availability or price changes.</p>
            <p>Hexowatch works 24/7 to help spot trends, spy on your competitors, visually check your website, keep an archive of every change and 
              turn any website into your own private data source accessing changes as a downloadable CSV file, google sheets or via Zapier.</p>
            
          </div>

          <a href="/pricing">
            Click here to monitor any website and get started in minutes - no software, proxies, or programming required
          </a>
        </div>
      </Section>
    </Layout>
  );
};

export default UseCasesItem;
